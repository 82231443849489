import React, { useEffect, useState } from "react";
import {
  SelectionMode,
  DetailsRow,
  createTheme,
  Separator,
  Dialog,
  DialogType,
  DialogFooter,
  mergeStyles,
  Stack,
  ShimmeredDetailsList,
  PrimaryButton,
  Icon,
  DefaultButton,
  ScrollablePane,
  Sticky,
  Checkbox,
  Toggle
} from "@fluentui/react";
import { useBoolean, useId } from "@uifabric/react-hooks";
import { Link } from "react-router-dom";
import { MessageActions } from "../Common/Utils/Message";
import SingleDatePicker from "../Components/DateRangePicker";
import ManagementAPI from "../Common/API/managementAPI";
import ResponseErrorHandler from "../Common/responseErrorHandler";
import _ from "lodash";
import dayjs from "dayjs";
import { overflowCellHover, PageHeader } from "../Common/customStyle";
import { btoa } from "../Common/Utils/base64";
import { dateTimeFormatUS, defaultTopic } from "../Common/constants";
import Resources from "../Common/resources";
import { envId } from "../Common/Config";
import TaxonomyMetadataTaskList from "../Components/TaxonomyMetadataTaskList";
import getMetadataTask from "../Common/Utils/deSerializeUtils";
import BackHomeButton from "../Components/BackHomeButton";

const detailIconStyle = mergeStyles({
  fontSize: 15,
});

const defaultStackTokens = { childrenGap: 10 };

const refreshIconStyle = mergeStyles({
  fontSize: 10,
  marginLeft: 2,
});

const useManualTriggerNotifyDialog = () => {
  const [mdOnly, setMdOnly] = useState(false);
  const [
    hideManualTriggerNotifyDialog,
    { toggle: toggleHideManualTriggerNotifyDialog },
  ] = useBoolean(true);
  const labelId = useId("dialogLabel");
  const subTextId = useId("subTextLabel");

  const manualTriggerNotifyContentProps = {
    type: DialogType.normal,
    title: Resources.ManualTriggerDialog.title(mdOnly),
    closeButtonAriaLabel: Resources.ManualTriggerDialog.closeButtonAriaLabel,
    subText: Resources.ManualTriggerDialog.subText(mdOnly),
  };

  const manualTriggerNotifyModalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
      styles: { main: { maxWidth: 350 } },
    }),
    [labelId, subTextId]
  );

  return [
    hideManualTriggerNotifyDialog,
    toggleHideManualTriggerNotifyDialog,
    manualTriggerNotifyContentProps,
    manualTriggerNotifyModalProps,
    setMdOnly,
  ];
};

const useForceImportConfirmDialog = () => {
  const [
    hideForceImportConfirmDialog,
    { toggle: toggleHideForceImportConfirmDialog },
  ] = useBoolean(true);
  const labelId = useId("dialogLabel");
  const subTextId = useId("subTextLabel");

  const forceImportConfirmDialogContentProps = {
    type: DialogType.normal,
    subText: Resources.JobForceImportConfirm.SubText,
  };

  const forceImportConfirmModalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: { main: { maxWidth: 350 } },
    }),
    [labelId, subTextId]
  );

  return [
    hideForceImportConfirmDialog,
    toggleHideForceImportConfirmDialog,
    forceImportConfirmDialogContentProps,
    forceImportConfirmModalProps
  ];
}

const renderItemColumn = (item, _index, column) => {
  let content = item[column.fieldName];
  // work around fabric bug here
  if (content === true || content === false) {
    content = _.toString(content);
  }

  switch (column.key) {
    case "startedAt":
      content = dayjs(content).format(dateTimeFormatUS);
      break;
    case "updatedAt":
      content = dayjs(content).format(dateTimeFormatUS);
      break;
    default:
      break;
  }
  let customStyles = mergeStyles(overflowCellHover);
  return <span className={customStyles}>{content}</span>;
};

const renderRow = (props) => {
  const iconStyles = {
    root: {
      fontSize: "16px",
      height: "16px",
      width: "16px",
    },
  };

  const theme = createTheme({
    fonts: {
      medium: {
        fontSize: "14px",
      },
    },
  });

  const SeparatorStyles = {
    root: {
      selectors: {
        "::before": {
          background: theme.palette.themeTertiary,
          height: "2px",
        },
      },
      padding: "10px",
    },
  };

  const SeparatorTextStyle = {
    display: "flex",
    alignItems: "center",
    color: theme.palette.themeDarkAlt,
    fontWeight: "500",
  };

  if (props) {
    if (
      props.itemIndex === 0 &&
      props.item &&
      (props.item.jobStatus === "success" ||
        props.item.jobStatus === "partialSuccess")
    ) {
      return (
        <Stack>
          <DetailsRow {...props} />
          <Separator theme={theme} styles={SeparatorStyles}>
            <span style={SeparatorTextStyle}>
              <Icon iconName="Up" styles={iconStyles} /> &nbsp;
              {Resources.Jobs.LastSuccessLabel}
            </span>
          </Separator>
        </Stack>
      );
    }

    return <DetailsRow {...props} />;
  }
  return null;
};

const defaultProjectsOptions = [
  {
    key: "DevRel Offering Ontology",
    text: "DevRel Offering Ontology",
    isChecked: false,
  },
  {
    key: "Docs Allowlist Taxonomies",
    text: "Docs Allowlist Taxonomies",
    isChecked: false,
  },
];

const shimmeredDetailsListProps = {
  renderedWindowsAhead: 0,
  renderedWindowsBehind: 0,
};

function Jobs(props) {
  document.title = "Taxonomy Admin Portal";
  const {
    jobs,
    isLoading,
    taxonomyNames,
    setIsLoading,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = props;
  const [allowManualTrigger, setAllowManualTrigger] = useState(true);
  const [hasTriggerPermission, setHasTriggerPermission] = useState(false);
  const [
    hideManualTriggerNotifyDialog,
    toggleHideManualTriggerNotifyDialog,
    manualTriggerNotifyContentProps,
    manualTriggerNotifyModalProps,
    setMdOnly,
  ] = useManualTriggerNotifyDialog();
  const [hideProjectDialog, { toggle: toggleHideProjectDialog }] =
    useBoolean(true);
  const [projectsOptions, setProjectOptions] = useState(defaultProjectsOptions);
  const [metadataTasks, setMetadataTasks] = useState([]);
  const [forceImportChecked, setForceImportChecked] = useState(false);
  const [
    hideForceImportConfirmDialog,
    toggleHideForceImportConfirmDialog,
    forceImportConfirmDialogContentProps,
    forceImportConfirmModalProps
  ] = useForceImportConfirmDialog();

  useEffect(() => {
    const initProject = async () => {
      try {
        const projectsData = await ManagementAPI.getProjectNames();
        const projects = _.map(projectsData.data, (p) => {
          return { key: p, text: p, isChecked: false };
        });
        if (_.isArray(projects) && !_.isEmpty(projects)) {
          setHasTriggerPermission(true);
        }
        setProjectOptions(projects);
      } catch (error) {
        const message = _.get(
          error,
          "response.data.error",
          "Get projects failed"
        );
        MessageActions.error(message);
      }
    };
    initProject();
  }, []);

  const hasNonSelectedProjects = _.isEmpty(
    _.filter(projectsOptions, (p) => p.isChecked === true)
  );
  const projectDialogContentProps = {
    type: DialogType.normal,
    title: Resources.Jobs.SelectProjects,
    subText: Resources.Jobs.SelectProjectsDescription,
  };
  const projectModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } },
  };

  const updateMD = () => {
    if (!hasTriggerPermission) return;
    setMdOnly(true);
    if (
      hideManualTriggerNotifyDialog === true &&
      toggleHideManualTriggerNotifyDialog
    ) {
      setAllowManualTrigger(false);
      setIsLoading(true);
      ManagementAPI.import(true)
        .then((res) => {
          setAllowManualTrigger(true);
          setIsLoading(false);
          toggleHideManualTriggerNotifyDialog();
          MessageActions.success(
            Resources.ManualTriggerDialog.ImportSucceeded(true)
          );
          setEndDate(new Date());
        })
        .catch((error) => {
          setAllowManualTrigger(true);
          const unKnownError = (
            <span>
              {Resources.ManualTriggerDialog.ImportErrorMessage(true)}
              <a href="/#taxonomy" style={{ textDecoration: "none" }}>
                Refresh
                <Icon iconName="Refresh" className={refreshIconStyle} />
              </a>
            </span>
          );
          ResponseErrorHandler(error, unKnownError);
        });
    }
  };

  const manualTrigger = () => {
    if (!hasTriggerPermission) return;
    toggleHideProjectDialog();
  };

  const manualTriggerAction = () => {
    if (!hasTriggerPermission || hasNonSelectedProjects) return;
    const projectNamesToProcess = _.map(
      _.filter(projectsOptions, (p) => p.isChecked === true),
      (p) => p.key
    );
    setMdOnly(false);
    if (hideManualTriggerNotifyDialog === true) {
      setAllowManualTrigger(false);
      toggleHideProjectDialog();
      setIsLoading(true);
      const onSuccess = (res) => {
        setAllowManualTrigger(true);
        setIsLoading(false);
        const mgmtTasks = getMetadataTask(res && res.data);
        setMetadataTasks(mgmtTasks);
        toggleHideManualTriggerNotifyDialog();
        MessageActions.success(
          Resources.ManualTriggerDialog.ImportSucceeded(false)
        );
        setEndDate(new Date());
      };
      const onError = (error) => {
        setAllowManualTrigger(true);
        const unKnownError = (
          <span>
            {Resources.ManualTriggerDialog.ImportErrorMessage(false)}
            <a href="/#taxonomy" style={{ textDecoration: "none" }}>
              Refresh
              <Icon iconName="Refresh" className={refreshIconStyle} />
            </a>
          </span>
        );
        ResponseErrorHandler(error, unKnownError);
      };

      if (envId === "pubdev" || envId === "dev") {
        ManagementAPI.compoundGenerator(projectNamesToProcess)
          .then(() => {
            return ManagementAPI.import(false, forceImportChecked, projectNamesToProcess);
          })
          .then(onSuccess)
          .catch(onError);
      } else {
        ManagementAPI.import(false, forceImportChecked, projectNamesToProcess)
          .then(onSuccess)
          .catch(onError);
      }
    }
  };

  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <BackHomeButton />
      <h1 className={mergeStyles(PageHeader)}>
        {Resources.AdminPage.PageHeader}
      </h1>
      <div>
        <Stack
          horizontal
          horizontalAlign="end"
          verticalAlign="end"
          tokens={defaultStackTokens}
        >
          <SingleDatePicker
            initialDate={startDate}
            setDate={setStartDate}
            label="Start Date"
            inlineLabel={true}
          />
          <SingleDatePicker
            initialDate={endDate}
            setDate={setEndDate}
            label="End Date"
            inlineLabel={true}
          />
          <PrimaryButton
            disabled={!allowManualTrigger || !hasTriggerPermission}
            id="ManualTriggerJob"
            text={Resources.AdminPage.ManualTriggerBtn(false)}
            onClick={manualTrigger}
          />
          <DefaultButton
            disabled={!allowManualTrigger || !hasTriggerPermission}
            id="GenMDJob"
            text={Resources.AdminPage.ManualTriggerBtn(true)}
            onClick={updateMD}
          />
        </Stack>
      </div>
      <div>
        <ScrollablePane
          style={{ position: "absolute", top: "200px", bottom: "50px" }}
        >
          <ShimmeredDetailsList
            setKey="jobId"
            items={jobs || []}
            columns={[
              {
                key: "startedAt",
                name: "Start Time",
                fieldName: "startedAt",
                minWidth: 150,
              },
              {
                key: "updatedAt",
                name: "Update Time",
                fieldName: "updatedAt",
                minWidth: 150,
                maxWidth: 200,
              },
              {
                key: "updatedBy",
                name: "Updated By",
                fieldName: "updatedBy",
                minWidth: 150,
                maxWidth: 200,
              },
              {
                key: "jobType",
                name: "Type",
                fieldName: "jobType",
                minWidth: 150,
                maxWidth: 200,
              },
              {
                key: "jobStatus",
                name: "Status",
                fieldName: "jobStatus",
                minWidth: 150,
                maxWidth: 200,
              },
              {
                key: "detail",
                name: "Detail",
                minWidth: 200,
                onRender: (item, index) => {
                  let toId, name;
                  toId = item.jobId;
                  name = taxonomyNames[0] || defaultTopic;
                  if (
                    item.jobStatus === "success" ||
                    item.jobStatus === "partialSuccess"
                  ) {
                    return (
                      <Link to={`/taxonomy/detail/${toId}/${name}`}>
                        <Icon
                          iconName="NavigateExternalInline"
                          className={detailIconStyle}
                        />
                      </Link>
                    );
                  } else if (item.jobStatus === "failed") {
                    return (
                      <Link to={`/taxonomy/error/${item.failureReport}`}>
                        <Icon
                          iconName="NavigateExternalInline"
                          className={detailIconStyle}
                        />
                      </Link>
                    );
                  }
                },
              },
            ]}
            onRenderDetailsHeader={
              // tslint:disable-next-line:jsx-no-lambda
              (detailsHeaderProps, defaultRender) => (
                <Sticky>{defaultRender(detailsHeaderProps)}</Sticky>
              )
            }
            selectionMode={SelectionMode.none}
            onRenderItemColumn={renderItemColumn}
            onRenderRow={renderRow}
            shimmerLines={10}
            enableShimmer={isLoading}
            ariaLabelForShimmer={Resources.AdminPage.ariaLabelForShimmer}
            ariaLabelForGrid={Resources.AdminPage.ariaLabelForGrid}
            listProps={shimmeredDetailsListProps}
          />
        </ScrollablePane>
      </div>
      <Dialog
        styles={{
          main: {
            selectors: {
              // eslint-disable-next-line no-useless-computed-key
              ["@media (min-width: 480px)"]: {
                width: 550,
                minWidth: 450,
                maxWidth: "1000px",
              },
            },
          },
        }}
        hidden={hideManualTriggerNotifyDialog}
        onDismiss={toggleHideManualTriggerNotifyDialog}
        dialogContentProps={manualTriggerNotifyContentProps}
        modalProps={manualTriggerNotifyModalProps}
      >
        <TaxonomyMetadataTaskList metadataTasks={metadataTasks} />
        <DialogFooter>
          <PrimaryButton
            onClick={toggleHideManualTriggerNotifyDialog}
            text={Resources.ManualTriggerDialog.Ok}
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideProjectDialog}
        onDismiss={toggleHideProjectDialog}
        dialogContentProps={projectDialogContentProps}
        modalProps={projectModalProps}
      >
        <Stack tokens={defaultStackTokens}>
          {projectsOptions.map((o) => {
            return (
              <Checkbox
                key={o.key}
                label={o.text}
                checked={o.isChecked}
                onChange={(e, isChecked) => {
                  let newProjectOptions = _.cloneDeep(projectsOptions);
                  let itemIndex = _.findIndex(
                    newProjectOptions,
                    (p) => p.key === o.key
                  );
                  newProjectOptions[itemIndex].isChecked = isChecked;
                  setProjectOptions(newProjectOptions);
                }}
              />
            );
          })}
          <Toggle inlineLabel checked={forceImportChecked} label={Resources.JobForceImportConfirm.Label} onText={Resources.JobForceImportConfirm.OnText} offText={Resources.JobForceImportConfirm.OffText} onChange={(e, isChecked) => {
              if (isChecked) {
                toggleHideForceImportConfirmDialog();
              } else {
                setForceImportChecked(isChecked);
              }
            }} />
        </Stack>
        <DialogFooter>
          <PrimaryButton
            disabled={!hasTriggerPermission || hasNonSelectedProjects}
            onClick={manualTriggerAction}
            text={Resources.Jobs.Trigger}
          />
          <DefaultButton
            onClick={() => {
              toggleHideProjectDialog();
            }}
            text={Resources.Jobs.Cancel}
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideForceImportConfirmDialog}
        onDismiss={toggleHideForceImportConfirmDialog}
        dialogContentProps={forceImportConfirmDialogContentProps}
        modalProps={forceImportConfirmModalProps}
      >
        <DialogFooter>
          <PrimaryButton 
            onClick={() => {
              setForceImportChecked(true);
              toggleHideForceImportConfirmDialog();
            }} 
            text={Resources.JobForceImportConfirm.Confirm} 
          />
          <DefaultButton 
            onClick={() => {
              setForceImportChecked(false);
              toggleHideForceImportConfirmDialog();
            }} 
            text={Resources.Jobs.Cancel} 
          />
        </DialogFooter>
      </Dialog>    
    </div>
  );
}

export default Jobs;
