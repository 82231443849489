import { useEffect, useRef, memo  } from 'react';
import { Network, DataSet } from 'vis-network/standalone/umd/vis-network';

const getNodeColor = (nodeType) => {
  if (!nodeType) return "DarkSeaGreen";
  const colors = {
    skills: "Violet",
    applied_skills: "darkorange",
    appliedskills: "darkorange",
    certification: "hotpink",
    extracted_task: "cornflowerblue",
    module: "cyan",
    learningpath: "Tomato",
    examination: "LightGreen",
  };
  return colors[nodeType.toLowerCase()] || "DarkSeaGreen";
}
const getNodeTitle = (node) => {
  let title = [];
  Object.keys(node).forEach(key => {
    title.push(`${key}: ${node[key]}`);
  });
  return title.join('\n');
}

const Graph = ({ graphItems, setGraphIsLoading, isNode = false }) => {
  const networkRef = useRef(null);
  const nodes = graphItems.nodes.map((node) => ({
    id: node.id,
    label: node.label,
    color: {
      background: getNodeColor(node.type),
      border: node.selected?'red':getNodeColor(node.type),
    },
    shape: node.nodeShape? node.nodeShape : 'dot',
    title: getNodeTitle(node.raw),
    size: 20,
    borderWidth: node.selected? 5:0,
  }));
  const edges = graphItems.relationships.map((relationship) => ({
    from: relationship.source,
    to: relationship.target,
    label: relationship.relation ? relationship.relation : '',
    arrows: 'to',
  }));

  useEffect(() => {
    if (nodes.length === 0 && edges.length === 0) {
      setGraphIsLoading(false);
    }
    const container = networkRef.current;
    const data = {
      nodes: new DataSet(nodes),
      edges: new DataSet(edges),
    };
    const options = isNode ? {  layout: {
      hierarchical: {
        direction: 'UD', 
        sortMethod: 'directed', 
        },
      },
      physics: {
        enabled: false, 
      }
    } : {};
    const network = new Network(container, data, options);
    network.on('stabilizationIterationsDone', () => {
      setGraphIsLoading(false);
    });
    const style = document.createElement('style');
    style.innerHTML = `
      .vis-tooltip {
        white-space: pre-line;
      }
    `;
    document.head.appendChild(style);
    return () => {
      network.destroy();
      document.head.removeChild(style);
    };
  }, [nodes, edges, setGraphIsLoading]);
  return <div ref={networkRef} style={{ height: '70vh', minHeight: '500px' }} />;
};

export default memo(Graph);