import { useState, useEffect, memo} from "react";
import BackHomeButton from "../Components/BackHomeButton";
import { Pivot, PivotItem, Text, Spinner, SpinnerSize } from "@fluentui/react";
import GraphApiInput from "./Form/graphApiInput";
import GraphRagInput from "./Form/graphRagInput";
import PlanList from "./List/planList";
import Graph from "./Context/graph";
import ContextTable from "./Context/table";
import PromptInput from "./Form/promptInput";
import KnowledgeServiceInput from "./Form/knowledgeServiceInput";

const InputTypeGraphApi = "GraphApi";
const InputTypeGraphRag = "GraphRag";
const InputTypeKnowledgeService = "KnowledgeService";
const ContextTypeGraph = "Graph";
const ContextTypeTable = "Table";

const Rag = (props) => {
  document.title = "Rag";
  const [userQuery, setUserQuery] = useState("");
  const [graphItems, setGraphItems] = useState({});
  const [tmpGraphItems, setTmpGraphItems] = useState({});
  const [planItems, setPlanItems] = useState([]);
  const [selectedInputType, setSelectedInputType] = useState(InputTypeGraphApi);
  const [selectedContextType, setSelectedContextType] = useState(ContextTypeGraph);
  const [graphIsLoading, setGraphIsLoading] = useState(false);
  const [planIsLoading, setPlanIsLoading] = useState(false);

  useEffect(() => {
    document.body.style.overflowY = 'auto';

    return () => {
      document.body.style.overflowY = 'hidden';
    };
  }, []);

  const submitInput = (graphItems) => {
    setPlanItems([])
    let tmpItem = tmpGraphItems;
    tmpItem[selectedInputType] = graphItems;
    setTmpGraphItems(tmpItem);
    setGraphItems(graphItems)
  }

  const submitPrompt = (planItems) => {
    setPlanItems(planItems)
  }

  const handleInputPivotClick = (item) => {
    setSelectedInputType(item.props.itemKey)
    setSelectedContextType(item.props.itemKey === InputTypeKnowledgeService ? ContextTypeTable : ContextTypeGraph);
    setGraphItems(tmpGraphItems[item.props.itemKey]);
    setPlanItems([]);
    setGraphIsLoading(false);
    setPlanIsLoading(false);
  };
  const handleContextPivotClick = (item) => {
    setGraphIsLoading(true);
    setSelectedContextType(item.props.itemKey);
  };

  return (
    <div>
      <BackHomeButton />
      <div>
        <Pivot aria-label="" linkFormat="tabs" linkSize="large"  onLinkClick={handleInputPivotClick}>
          <PivotItem itemKey={InputTypeGraphApi} headerText={InputTypeGraphApi} alwaysRender={true}>
            <GraphApiInput submitInputFunc={submitInput} setUserQuery={setUserQuery} setGraphIsLoading={setGraphIsLoading} />
          </PivotItem>
          <PivotItem itemKey={InputTypeGraphRag} headerText={InputTypeGraphRag} alwaysRender={true}>
            <GraphRagInput submitInputFunc={submitInput} setUserQuery={setUserQuery} setGraphIsLoading={setGraphIsLoading} />
          </PivotItem>
          <PivotItem itemKey={InputTypeKnowledgeService} headerText={InputTypeKnowledgeService} alwaysRender={true}>
            <KnowledgeServiceInput submitInputFunc={submitInput} setUserQuery={setUserQuery} setGraphIsLoading={setGraphIsLoading} />
          </PivotItem>
        </Pivot>
      </div>
      {graphIsLoading ? (<Spinner size={SpinnerSize.large} />):""}
      {graphItems && "nodes" in graphItems ? (
        <div>
          <Text block styles={{ root: { marginTop: 10 } }}><b>Context</b></Text>
          <Pivot aria-label="" linkFormat="tabs" linkSize="large" selectedKey={selectedContextType}  onLinkClick={handleContextPivotClick}>
            <PivotItem itemKey={ContextTypeGraph} headerText={ContextTypeGraph}>
              <Graph graphItems={graphItems} setGraphIsLoading={setGraphIsLoading} />
            </PivotItem>
            <PivotItem itemKey={ContextTypeTable} headerText={ContextTypeTable}>
              <ContextTable graphItems={graphItems} setGraphIsLoading={setGraphIsLoading} />
            </PivotItem>
          </Pivot>
          <PromptInput userQuery={userQuery} graphItems={graphItems} setGraphItems={setGraphItems} submitPromptFunc={submitPrompt} inputType={selectedInputType} setPlanIsLoading={setPlanIsLoading} setPlanItems={setPlanItems} />
        </div>
      ) : ""}
      {planIsLoading ? (<Spinner size={SpinnerSize.large} />):""}
      {planItems?.length > 0 ? (<PlanList items={planItems} setPlanIsLoading={setPlanIsLoading} />):""}
    </div>
  );
};

export default memo(Rag);
